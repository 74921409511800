<template>
  <div class="vg_wrapper">
    <EditHeader
      :isShow="isShow"
      :btn="btn"
      :special="true"
      :strForm="{ status: formStatus }"
      @openEdit="openEdit"
      @closeEdit="closeEdit"
      @submit="submit"
      @refresh="initData"
    >
      <div slot="functionButton" class="vg_ml_8">
        <el-button
          v-if="formStatus === 2 && requisition_type !== 1 && requisition_type !== 3"
          :disabled="!isShow"
          class="vg_mb_8"
          size="small"
          type="warning"
          @click="generateInvoice"
        >
          生成采购发票
        </el-button>
        <el-button
          v-if="formStatus === 2 && requisition_type === 1"
          :disabled="!isShow"
          class="vg_mb_8"
          size="small"
          type="warning"
          @click="generateOtherPurchaseInvoices"
        >
          生成其他采购发票
        </el-button>
      </div>
      <div slot="otherButton">
        <div class="flexHV vg_mr_8">
          <span>通知单号：</span>
          <el-input disabled size="small" :value="requisition_no" style="width: 160px"></el-input>
        </div>
      </div>
      <div slot="specialStatus">
        <div class="flexHV">
          <span class="vg_tag_label">状态：</span>
          <el-tag :type="helper.getStatusName(formStatus).type">{{ helper.getStatusName(formStatus).name }}</el-tag>
        </div>
      </div>
    </EditHeader>
    <DynamicForm
      v-if="requisition_type !== 1"
      v-bind="$attrs"
      :total-width="mainForm.totalWidth"
      :split="mainForm.split"
      :form-list="mainForm.formProperties"
      ref="dynamicForm"
      class="dynamicForm"
      :is-show="isShow"
    />
    <DynamicForm
      v-if="requisition_type === 1"
      ref="dynamicForm"
      :form-list="mainForm.formProperties2"
      :is-show="isShow"
      :split="mainForm.split"
      :total-width="mainForm.totalWidth"
      class="dynamicForm"
      v-bind="$attrs"
    />
    <div v-if="requisition_type !== 1 && requisition_type !== 3">
      <el-button :disabled="isShow" class="vg_mb_8" type="info" size="small" @click="split()">拆分开票数量</el-button>
    </div>
    <div v-if="requisition_type === 3">
      <el-button :disabled="isShow" class="vg_mb_8" type="primary" size="small" @click="includesModrFees()"> 含入合同费用 </el-button>
    </div>
    <DynamicUTable
      v-if="requisition_type === 3"
      key="dynamicUTable3"
      ref="dynamicUTable"
      :tableData="subTableData"
      :columns="requisitionSubTableProperties3"
      :need-pagination="false"
      :is-show="isShow"
      @selection-change="val => (subSelections = val)"
      :show-summary="true"
      :summariesColumns="['prod_num', 'invoice_moneyfee']"
      :needSummaryValues="['prod_num', 'invoice_moneyfee']"
      @getSummary_prod_num="val => changeProdNum(val)"
      @getSummary_invoice_moneyfee="val => changeInvoiceMoney(val)"
      :row-click="(row, column, event) => (currentRow = row)"
      :computedData="{ prod_price: prod_price, invoice_moneyfee: invoice_moneyfee, tax_accrued: tax_accrued3, tax_refund: tax_refund3, prod_money: prod_money }"
      :row-style="setRowStyle"
    >
    </DynamicUTable>
    <DynamicUTable
      v-if="requisition_type !== 1 && requisition_type !== 3"
      ref="dynamicUTable"
      key="dynamicUTable"
      :tableData="subTableData"
      :columns="requisitionSubTableProperties"
      :need-pagination="false"
      :is-show="isShow"
      @selection-change="val => (subSelections = val)"
      :show-summary="true"
      :summariesColumns="['prod_num', 'invoice_money', 'tax_accrued', 'tax_refund', 'prod_money']"
      :needSummaryValues="['prod_num', 'invoice_money']"
      @getSummary_prod_num="val => changeProdNum(val)"
      @getSummary_invoice_money="val => changeInvoiceMoney(val)"
      :row-click="(row, column, event) => (currentRow = row)"
      :computedData="{ invoice_money: invoice_money, tax_accrued: tax_accrued, tax_refund: tax_refund, prod_money: prod_money }"
      :row-style="setRowStyle"
    >
    </DynamicUTable>
    <DynamicUTable
      v-if="requisition_type === 1"
      ref="dynamicUTable"
      key="dynamicUTable2"
      :columns="requisitionSubTableProperties2"
      :is-show="isShow"
      :need-pagination="false"
      :tableData="subTableData"
      :row-style="setRowStyle"
    >
    </DynamicUTable>
    <el-dialog title="拆分开票数量" :visible.sync="splitDialogVisible" width="35%">
      <el-form :inline="true" ref="splitForm" :model="splitForm" label-width="120px">
        <el-form-item label="开票数量:">
          <el-input-number v-model="splitForm.prod_num" :max="splitForm.maxCWeight" :min="0"></el-input-number>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="splitConfirm">确 定</el-button>
      </span>
    </el-dialog>
    <el-row class="vg_mt_16 vd_bortop">
      <el-col>
        <inputUser :isCookie="false" :stffForm="stffForm" ref="userData"></inputUser>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import EditHeader from '@/views/component/editHeaderLine.vue';
import inputUser from '@/views/component/inputUser';
import DynamicForm from '@/components/DynamicFormV2.vue';
import { cloneDeep } from 'lodash';
import {
  requisitionMainFormProperties,
  requisitionMainFormProperties2,
  requisitionSubTableProperties,
  requisitionSubTableProperties2,
  requisitionSubTableProperties3
} from '@/views/FinanceManagement/RequisitionManage/requisition';
import DynamicUTable from '@/components/table/DynamicUTable.vue';
import { requisitionAPI } from '@api/modules/requisition';
import { BigNumber } from 'bignumber.js';
import { requiredReminderAndUGrid } from '@assets/js/domUtils';
import { changeStaffForm, getCptt } from '@api/public';
import { getNoCatch, postNoCatch } from '@api/request';
import { pinvAPI } from '@api/modules/pinv';
import { arrayDeRepeat, getArrayIds } from '@assets/js/arrayUtils';
import { optnAPI } from '@api/modules/optn';
import UrlEncode from '@assets/js/UrlEncode';

export default {
  name: 'requisitionEditMain',
  components: {
    DynamicUTable,
    DynamicForm,
    EditHeader,
    inputUser
  },
  data() {
    return {
      isShow: true,
      btn: {},
      stffForm: {},
      splitForm: {},
      mainForm: {
        split: 8,
        totalWidth: 24,
        formProperties: cloneDeep(requisitionMainFormProperties),
        formProperties2: cloneDeep(requisitionMainFormProperties2)
      },
      subTableData: [],
      subSelections: [],
      requisitionSubTableProperties: cloneDeep(requisitionSubTableProperties),
      requisitionSubTableProperties2: cloneDeep(requisitionSubTableProperties2),
      requisitionSubTableProperties3: cloneDeep(requisitionSubTableProperties3), //我司结算辅料
      formStatus: 0,
      requisition_no: '',
      requisition_id: '',
      splitDialogVisible: false,
      currentRow: {},
      requisition_type: '' //0外销 1 内贸 2托收 3我司结算-辅料
    };
  },
  computed: {
    // 开票金额 = 开票数量 * 开票单价
    invoice_money() {
      if (!this.currentRow) return;
      let { prod_num, prod_price } = this.currentRow;
      let temp = new BigNumber(prod_num).times(prod_price);
      if (!temp.isFinite()) {
        this.currentRow.invoice_money = 0;
        return '自动计算';
      }
      this.currentRow.invoice_money = temp.toFixed(4);
      return temp.toFixed(4);
    },
    //开票单价 = 开票金额（含费用） / 开票数量  我司结算辅料专用计算
    prod_price() {
      if (!this.currentRow) return;
      let { invoice_moneyfee, prod_num } = this.currentRow;
      let temp = new BigNumber(Number(invoice_moneyfee) / Number(prod_num));
      if (!temp.isFinite()) {
        this.currentRow.prod_price = 0;
        return 0;
      }
      this.currentRow.prod_price = parseFloat(temp.toFixed(12)).toString();
      return parseFloat(temp.toFixed(12)).toString();
    },
    //开票金额(含费用) = 开票金额 + 费用金额  我司结算辅料专用计算
    invoice_moneyfee() {
      if (!this.currentRow) return;
      let { invoice_money, modr_fetotal } = this.currentRow;
      let temp = new BigNumber(Number(invoice_money) + Number(modr_fetotal));
      if (!temp.isFinite()) {
        this.currentRow.invoice_moneyfee = 0;
        return 0;
      }
      this.currentRow.invoice_moneyfee = parseFloat(temp.toFixed(2)).toString();
      return parseFloat(temp.toFixed(2)).toString();
    },
    // 税额 = 开票金额 / ( 1 + 税率 / 100)
    tax_accrued() {
      if (!this.currentRow) return;
      let { invoice_money, tax_rate } = this.currentRow;
      let temp = new BigNumber(invoice_money - invoice_money / (1 + tax_rate / 100));
      if (!temp.isFinite()) {
        this.currentRow.tax_accrued = 0;
        return '自动计算';
      }
      this.currentRow.tax_accrued = temp.toFixed(2);
      return temp.toFixed(2);
    },
    // 税额 = 开票金额（含费用） / ( 1 + 税率 / 100)  我司结算辅料专用
    tax_accrued3() {
      if (!this.currentRow) return;
      let { invoice_moneyfee, tax_rate } = this.currentRow;
      let temp = new BigNumber(invoice_moneyfee - invoice_moneyfee / (1 + tax_rate / 100));
      if (!temp.isFinite()) {
        this.currentRow.tax_accrued = 0;
        return '自动计算';
      }
      this.currentRow.tax_accrued = temp.toFixed(2);
      return temp.toFixed(2);
    },
    // 退税额 = 开票金额 / ( 1 + 退税率 / 100)
    tax_refund() {
      if (!this.currentRow) return;
      let { invoice_money, tax_refundrate } = this.currentRow;
      let temp = new BigNumber(invoice_money - invoice_money / (1 + tax_refundrate / 100));
      if (!temp.isFinite()) {
        this.currentRow.tax_refund = 0;
        return '自动计算';
      }
      this.currentRow.tax_refund = temp.toFixed(2);
      return temp.toFixed(2);
    },
    // 退税额 = 开票金额（含费用） / ( 1 + 退税率 / 100)   我司结算辅料专用
    tax_refund3() {
      if (!this.currentRow) return;
      let { invoice_moneyfee, tax_refundrate } = this.currentRow;
      let temp = new BigNumber(invoice_moneyfee - invoice_moneyfee / (1 + tax_refundrate / 100));
      if (!temp.isFinite()) {
        this.currentRow.tax_refund = 0;
        return '自动计算';
      }
      this.currentRow.tax_refund = temp.toFixed(2);
      return temp.toFixed(2);
    },
    // 报关金额 = 开票数量 * 报关单价
    prod_money() {
      if (!this.currentRow) return;
      let { prod_num, prod_dusd } = this.currentRow;
      let temp = new BigNumber(prod_num).times(prod_dusd);
      if (!temp.isFinite()) {
        this.currentRow.prod_money = 0;
        return '自动计算';
      }
      this.currentRow.prod_money = temp.toFixed(4);
      return temp.toFixed(4);
    }
  },
  watch: {
    requisition_type(newValue, oldValue) {
      this.mainForm.formProperties[0].show = newValue !== 3;
    }
  },
  mounted() {
    this.initData();
  },
  methods: {
    async initData() {
      await this.getRequisitionData();
      await this.changeProperties();
    },
    async changeProperties() {
      let cpttList = await getCptt();
      this.mainForm.formProperties.find(({ prop }) => prop === 'cptt_id').options = cpttList;
      this.mainForm.formProperties2.find(({ prop }) => prop === 'cptt_id').options = cpttList;
      //改为中文单位
      await getNoCatch(optnAPI.getOptnByPermId, { perm_id: 10008 }).then(({ data }) => {
        this.requisitionSubTableProperties.find(({ prop }) => prop === 'prod_unit').subItem.options = Array.from(data.form.optn_cntt_list, ({ param1 }) => {
          return { value: param1, label: param1 };
        });
      });
      //海关编码
      await getNoCatch(optnAPI.getOptnByPermId, { perm_id: 10019 }).then(({ data }) => {
        this.requisitionSubTableProperties.find(({ prop }) => prop === 'prod_hsname').subItem.options = Array.from(data.form.optn_cntt_list, ({ param2 }) => {
          return { value: param2, label: param2 };
        });
      });
    },
    //启用编辑
    openEdit() {
      this.isShow = false;
      this.$emit('isShow', this.isShow);
    },
    //撤销编辑
    closeEdit() {
      this.$confirm('是否撤销编辑?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      })
        .then(() => {
          this.isShow = true;
          this.$emit('isShow', this.isShow);
          this.initData();
          this.$message({
            type: 'info',
            message: '已撤销编辑!'
          });
        })
        .catch(() => {});
    },
    getRequisitionData() {
      const props = JSON.parse(UrlEncode.decode(this.$route.query.key));
      requisitionAPI.getRequisitionById({ requisition_id: props.form_id }).then(async ({ data }) => {
        this.btn = data.btn;
        this.requisition_no = data.form.requisition_no;
        this.requisition_id = data.form.requisition_id;
        this.requisition_type = data.form.requisition_type;
        this.formStatus = data.form.status;
        changeStaffForm(this.stffForm, data.form);
        if (data.form.requisition_type !== 1) {
          this.mainForm.formProperties.forEach(item => (item.model = data.form[item.prop]));
          this.requisitionSubTableProperties.find(({ prop }) => prop === 'podr_no').subItem.options = Array.from(data.podrList, item => {
            return { value: item, label: item };
          });
        } else {
          //requisition_type == 1 内贸的单独处理
          this.mainForm.formProperties2.forEach(item => (item.model = data.form[item.prop]));
        }
        //仅我司结算材料
        this.mainForm.formProperties.find(x => x.prop === 'supp_abbr').show = false;
        if (data.form.requisition_type === 3) {
          this.mainForm.formProperties.find(x => x.prop === 'supp_abbr').show = true;
          //获取单位
          await getNoCatch(optnAPI.getOptnByPermId, { perm_id: 10008 }).then(({ data }) => {
            this.requisitionSubTableProperties3.find(({ prop }) => prop === 'prod_unit').subItem.options = Array.from(
              data.form.optn_cntt_list,
              ({ param1 }) => {
                return { value: param1, label: param1 };
              }
            );
          });
          //获取报关中文品名
          await getNoCatch(optnAPI.getOptnByPermId, { perm_id: 10019 }).then(({ data }) => {
            this.requisitionSubTableProperties3.find(({ prop }) => prop === 'prod_hsname').subItem.options = Array.from(
              data.form.optn_cntt_list,
              ({ param2 }) => {
                return { value: param2, label: param2 };
              }
            );
            //联动修改海关编码
            this.requisitionSubTableProperties3.find(({ prop }) => prop === 'prod_hsname').subItem.change = (val, row, options) => {
              let find = data.form.optn_cntt_list.find(item => item.param2 === val);
              this.$set(row, 'prod_hscode', find.param1);
            };
          });
        }
        this.subTableData = data.form.requisitionPartList;
        setTimeout(() => {
          this.$refs.dynamicUTable2?.reloadTable();
        }, 1000);
      });
    },
    submit() {
      this.$confirm('确认保存?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          let dynamicUTable = await this.$refs.dynamicUTable.fullValidateMsg();
          this.$refs.dynamicForm.$refs.form.validate((valid, object) => {
            if (valid && !dynamicUTable) {
              this.saveInfo();
            } else {
              let tempStr = '';
              if (dynamicUTable) tempStr += `子表-${dynamicUTable}`;
              requiredReminderAndUGrid(object, tempStr);
            }
          });
        })
        .catch(err => console.error(err));
    },
    saveInfo() {
      requisitionAPI.editRequisition(this.getSaveData()).then(({ data }) => {
        this.$message.success('保存成功!');
        this.initData();
        this.isShow = true;
        this.$emit('isShow', this.isShow);
      });
    },
    getSaveData() {
      let formModel = this.$refs.dynamicForm.getFormModel();
      formModel.requisition_id = this.requisition_id;
      formModel.requisition_type = this.requisition_type;
      formModel.requisition_no = this.requisition_no;
      formModel.requisitionPartList = this.subTableData;
      return formModel;
    },
    split() {
      if (this.subSelections.length !== 1) return this.$message.warning('请选择一条数据!');
      this.splitDialogVisible = true;
      this.splitForm.maxCWeight = this.subSelections[0].prod_num - 1;
    },
    async splitConfirm() {
      if (!this.splitForm.prod_num) return this.$message.warning('请输入开票数量!');
      let { prod_num } = this.subSelections[0];
      let splitObj = cloneDeep(this.subSelections[0]);
      splitObj.prod_num = new BigNumber(this.splitForm.prod_num).toFixed(2, 0);

      let findIndex = this.subTableData.findIndex(x => x.index === splitObj.index);
      // 不四舍五入
      this.subTableData[findIndex].prod_num = new BigNumber(Number(prod_num) - this.splitForm.prod_num).toFixed(2, 0);
      splitObj.requisition_part_id = 0;
      splitObj.isSplit = true;
      splitObj._XID = null;
      this.subTableData.unshift(splitObj);
      this.currentRow = this.subTableData[findIndex + 1];
      await this.$refs.dynamicUTable.$refs.multiTable.setActiveRow(this.currentRow);
      this.currentRow = splitObj;
      await this.$refs.dynamicUTable.$refs.multiTable.setActiveRow(this.currentRow);
      await this.$refs.dynamicUTable.clearSelection();
      this.splitForm = {};
      this.splitDialogVisible = false;
    },
    generateInvoice() {
      let requestBody = {};
      if (this.requisition_type !== 3) {
        if (this.subSelections.length === 0) return this.$message.warning('请选择数据!');
        let isMultiple = arrayDeRepeat(getArrayIds(this.subSelections, 'supp_abbr')).length > 1;
        if (isMultiple) return this.$message.warning('请选择相同供应商才可合并生成采购发票');
        requestBody = {
          requisition_id: this.requisition_id,
          requisition_part_ids: getArrayIds(this.subSelections, 'requisition_part_id').toString()
        };
      } else {
        requestBody = {
          requisition_id: this.requisition_id,
          requisition_part_ids: getArrayIds(this.subTableData, 'requisition_part_id').toString()
        };
      }
      postNoCatch(pinvAPI.addPinv, requestBody).then(({ data }) => {
        this.jump('pinv_list', { perm_id: 132, form_id: data.form_id, t: Date.now() });
      });
    },
    generateOtherPurchaseInvoices() {
      postNoCatch(pinvAPI.addOinvByRequisition, { requisition_id: this.requisition_id }).then(({ data }) => {
        this.jump('/oinv_edit', {
          key: UrlEncode.encode(
            JSON.stringify({
              perm_id: 148,
              form_id: data.form_id
            })
          )
        });
      });
    },
    changeProdNum(val) {
      this.mainForm.formProperties.find(({ prop }) => prop === 'invoice_num').model = Number(val);
    },
    changeInvoiceMoney(val) {
      this.mainForm.formProperties.find(({ prop }) => prop === 'invoice_price').model = Number(val).toFixed(2);
    },
    setRowStyle({ row, rowIndex }) {
      row.index = rowIndex;
      if (row.pinv_start === 1) {
        return { background: 'rgb(135,206,235)' };
      }
    },
    //含入合同费用
    includesModrFees() {
      if (this.subSelections.length !== 1) return this.$message.warning('请选择一条数据!');
      if (this.subSelections.length > 1) return this.$message.warning('只能选择一条数据!');
      requisitionAPI
        .includesModrFees({
          modr_id: this.subSelections[0].modr_id,
          requisition_part_id: this.subSelections[0].requisition_part_id
        })
        .then(({ data }) => {
          this.$set(
            this.subTableData.find(x => x.requisition_part_id === this.subSelections[0].requisition_part_id),
            'modr_fetotal',
            data.modr_fetotal
          );
          this.$refs.dynamicUTable.updateData();
          this.$message.success('含入成功！');
        });
    }
  }
};
</script>

<style scoped lang="scss"></style>
