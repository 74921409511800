<template>
  <div class="vg_wrapper">
    <el-tabs v-model="activeName" type="border-card">
      <el-tab-pane label="单据信息" name="first" :key="'first'">
        <childTab1 @isShow="isShow" ref="childTab1" />
      </el-tab-pane>
      <el-tab-pane label="审批流转" name="second" :key="'second'">
        <childTab3 :isShow="isSho" @draftState="draftState" />
      </el-tab-pane>
      <el-tab-pane label="相关文档" name="third" :key="'third'">
        <childTab2 />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import RequisitionEditMain from '@/views/FinanceManagement/RequisitionManage/TabChild/RequisitionEditMain';
import RequisitionEditAbout from '@/views/FinanceManagement/RequisitionManage/TabChild/RequisitionEditAbout';
import RequisitionEditAppr from '@/views/FinanceManagement/RequisitionManage/TabChild/RequisitionEditAppr';
export default {
  name: 'RequisitionEdit',
  components: {
    childTab1: RequisitionEditMain,
    childTab2: RequisitionEditAbout,
    childTab3: RequisitionEditAppr
  },
  data() {
    return {
      activeName: 'first',
      isSho: true
    };
  },
  created() {},
  methods: {
    isShow(val) {
      this.isSho = val;
    },
    draftState() {
      this.$refs.childTab1.initData();
    }
  }
};
</script>

<style scoped lang="scss"></style>
